import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/httpService";
import { cloneDeep } from "lodash";
const API_URL = axios.Api;
const REPORTAPI = axios.ReportAPI || "";
const USERMANAGEAPI = axios.UserManageAPI || "";
const INVENTORYAPI = axios.InventoryAPI || "";
const NOTIFICATIONAPI = axios.NotificationApi || "";

type User = {
  email: string;
  password: string;
};

type NewUser = User & {
  name: string;
};

type UserBasicInfo = {
  userDetail: {
    firstName: string;
    lastName: string;
    email: string;
    contactNo: string;
  };
  customerDetail: null;
};

type AuthApiState = {
  userInfo?: UserBasicInfo | null;
  status: "idle" | "loading" | "failed";
  error: string | null;
  permissions: any;
  userSettings: any;
  salesUserAccess: any;
  registrationDetails: any;
};

const initialState: AuthApiState = {
  userInfo: localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") as string)
    : null,
  permissions: localStorage.getItem("permissions")
    ? JSON.parse(localStorage.getItem("permissions") as string)
    : null,
  registrationDetails: localStorage.getItem("registrationDetails")
    ? JSON.parse(localStorage.getItem("registrationDetails") as string)
    : null,
  salesUserAccess: localStorage.getItem("salesUserAccess")
    ? JSON.parse(localStorage.getItem("salesUserAccess") as string)
    : null,
  userSettings: localStorage.getItem("userSettings")
    ? JSON.parse(localStorage.getItem("userSettings") as string)
    : null,
  status: "idle",
  error: null,
};

export const getRcpReport = createAsyncThunk(
  "Reports/GetRCPOrderLineDetails",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetRCPOrderLineDetails`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getRcpSummaries = createAsyncThunk(
  "Reports/GetRCPSummaries",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetRCPSummaries`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getPaymentSchedules = createAsyncThunk(
  "Reports/GetRCPPaymentSchedules",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetRCPPaymentSchedules`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getDisbursementSummary = createAsyncThunk(
  "Reports/DisbursementSummary",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/DisbursementSummary`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getInvoiceSummary = createAsyncThunk(
  "Reports/InvoiceSummary",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/InvoiceSummary`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getSFGSummary = createAsyncThunk(
  "Reports/SFGSummary",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/SFGSummary`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const postVerifySummary = createAsyncThunk(
  "Reports/VerifyDisbursementSummary",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/VerifyDisbursementSummary`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getPartneredSummaryDetails = createAsyncThunk(
  "Reports/GetPartneredSummaryDetails",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetPartneredSummaryDetails`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const GetDrugLiquidation = createAsyncThunk(
  "Reports/GetDrugLiquidation",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetDrugLiquidation`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getDisposalAccount = createAsyncThunk(
  // "api/RequestKit/GetDisposalAccounts",
  "api/Customers/GetAccountSelection",
  async (body: any) => {
    let finalData;
    let queryParams = "";
    if (body.data !== "") {
      queryParams += `serviceOffering=${body.data.toString()}`;
    }
    // if (body.customerIds !== "") {
    //   queryParams += `${queryParams !== "" ? "&" : ""}customerIds=${body.customerIds.toString()}`;
    // }
    await axios
      .post(
        API_URL +
        INVENTORYAPI +
        (queryParams !== ""
          ? `Customers/GetAccountSelection?${queryParams}`
          : `Customers/GetAccountSelection`),
        { customerIds: `${body.customerIds.toString()}` }
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const postRequestKit = createAsyncThunk(
  "/api/RequestKit",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + INVENTORYAPI + `RequestKit`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const postRequestOnSiteService = createAsyncThunk(
  "/api/RequestOnSiteService",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + INVENTORYAPI + `RequestOnSiteService`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getUser = createAsyncThunk("User/GetUser", async () => {
  const response = await axios.get(API_URL + USERMANAGEAPI + `User/GetUser`);
  localStorage.setItem("userInfo", JSON.stringify(response.data));
  return response?.data;
});

export const getUserModulePermission = createAsyncThunk("User/GetUserModulePermission", async (body: any, thunkAPI) => {
  const response = await axios.get(API_URL + USERMANAGEAPI + `User/GetUserModulePermission`);

  thunkAPI.dispatch(setSalesUser(response?.data?.data));

  return response?.data;
});

export const getUserSettings = createAsyncThunk("User/GetUserSettings", async (body: any, thunkAPI) => {
  const response = await axios.post(API_URL + USERMANAGEAPI + `User/GetUserSettings`, body);

  Object.keys(response?.data?.data?.gridSettings).map((key) => {
    if (response?.data?.data?.gridSettings?.[key]?.isResetToDefault) {
      response.data.data.gridSettings[key].isResetToDefault = false;
    }
  })

  thunkAPI.dispatch(setUserSettings(response?.data?.data));

  return response?.data;
});

export const saveUserSettings = createAsyncThunk("User/SaveUserSettings", async (body: any, thunkAPI) => {
  const response = await axios.post(API_URL + USERMANAGEAPI + `User/SaveUserSettings`, body);

  Object.keys(response?.data?.data?.gridSettings).map((key) => {
    if (response?.data?.data?.gridSettings?.[key]?.isResetToDefault) {
      response.data.data.gridSettings[key].isResetToDefault = false;
    }
  })

  localStorage.setItem("userSettings", JSON.stringify(response?.data?.data || {}));
  thunkAPI.dispatch(setUserSettings(response?.data?.data));

  return response?.data;
});

export const getUserSelectedAccount = createAsyncThunk("User/GetUserSelectedAccount", async (body: any, thunkAPI) => {
  const response = await axios.get(API_URL + USERMANAGEAPI + `User/GetUserSelectedAccount`);

  if (localStorage.getItem("AzureB2CImpersonate")) {

    Object.keys(response?.data?.data?.gridSettings).map((key) => {
      if (response?.data?.data?.gridSettings?.[key]?.isResetToDefault) {
        response.data.data.gridSettings[key].isResetToDefault = false;
      }
    });

    thunkAPI.dispatch(setUserSettings(response?.data?.data));
  }

  return response?.data;
});

export const saveUserSelectedAccount = createAsyncThunk("User/SaveUserSelectedAccount", async (body: any, thunkAPI) => {
  const response = await axios.post(API_URL + USERMANAGEAPI + `User/SaveUserSelectedAccount`, body);

  Object.keys(response?.data?.data?.gridSettings).map((key) => {
    if (response?.data?.data?.gridSettings?.[key]?.isResetToDefault) {
      response.data.data.gridSettings[key].isResetToDefault = false;
    }
  });

  localStorage.setItem("userSettings", JSON.stringify(response?.data?.data || {}));
  thunkAPI.dispatch(setUserSettings(response?.data?.data));

  return response?.data;
});

export const editUserProfile = createAsyncThunk(
  "User/editUser",
  async (body: any) => {
    let finalData;
    await axios
      .put(API_URL + USERMANAGEAPI + `User/UpdateUserProfile`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getDisbursementDetails = createAsyncThunk(
  "Reports/DisbursementDetail",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/DisbursementDetail`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getInvoiceDetails = createAsyncThunk(
  "Reports/GetInvoiceDetail",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetInvoiceDetail`, body)
      .then((response) => {
        finalData = response.data;
      });
    return finalData;
  }
);

export const getOverstockSummary = createAsyncThunk(
  "Reports/GetOverstockSummary",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetOverstockSummary`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getKitSummaryList = createAsyncThunk(
  "RequestKit/GetRequestKits",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + INVENTORYAPI + `RequestKit/GetRequestKits`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getServies = createAsyncThunk(
  "RequestOnSiteService/GetRequestOnSiteServices",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL +
        INVENTORYAPI +
        `RequestOnSiteService/GetRequestOnSiteServices`,
        body
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const getEPAComplianceNotification = createAsyncThunk(
  "Reports/EPAComplianceNotification",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/EPAComplianceNotification`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getDestructionFormList = createAsyncThunk(
  "Reports/Form41",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/Form41`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getDestructionManifests = createAsyncThunk(
  "Reports/DestructionManifests",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/DestructionManifests`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getInventoryReports = createAsyncThunk(
  "Reports/GetInventories",
  async (body: any) => {
    const response = await axios.post(
      API_URL + REPORTAPI + `Reports/GetInventories`,
      body
    );
    return response.data;
  }
);

export const getUserList = createAsyncThunk("User/List", async (body: any) => {
  const response = await axios.post(
    API_URL + USERMANAGEAPI + `User/List`,
    body
  );
  return response.data;
});

export const getAccountsForUser = createAsyncThunk(
  "api/Customers/GetAccountSelection",
  async (body: any) => {
    const response = await axios.post(
      API_URL + INVENTORYAPI + "Customers/GetAccountSelection",
      body
    );

    return response?.data;
  }
);

export const createUser = createAsyncThunk("User/Create", async (body: any) => {
  const response = await axios.post(
    API_URL + USERMANAGEAPI + `User/Create`,
    body
  );

  return response?.data;
});

export const editUser = createAsyncThunk("User/Update", async (body: any) => {
  const { id, ...rest } = body;

  const response = await axios.put(
    API_URL + USERMANAGEAPI + `User/${id}/Update`,
    rest
  );

  return response?.data;
});

export const deleteUser = createAsyncThunk(
  "User/Delete",
  async (userId: any) => {
    const response = await axios.delete(
      API_URL + USERMANAGEAPI + `User/${userId}/Delete`,
      {}
    );

    return response?.data;
  }
);

export const deleteReturnOrders = createAsyncThunk(
  "ReturnOrder/Delete",
  async (orderId: any) => {
    const response = await axios.delete(
      API_URL + INVENTORYAPI + `ReturnOrder/DeleteReturnOrders/${orderId}`,
      {}
    );

    return response?.data;
  }
);

export const updateUserStatus = createAsyncThunk(
  "User/UpdateStatus",
  async (body: any) => {
    const { id, status } = body;

    const response = await axios.put(
      API_URL + USERMANAGEAPI + `User/${id}/UpdateStatus?status=${status}`
    );

    return response?.data;
  }
);

export const getTrackShipmentDetails = createAsyncThunk(
  "Reports/TrackShipment",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/TrackShipment`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getUpComingReturns = createAsyncThunk(
  "Reports/UpcomingReturn",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/UpcomingReturn`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const getOrderLineDetails = createAsyncThunk(
  "Reports/OrderLineDetail",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/OrderLineDetail`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const GetSchedult_II_III_V_ChartData = createAsyncThunk(
  "Reports/GetSchedult_II_III_V_ChartData",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL + REPORTAPI + `Dashboard/GetSchedule_II_III_V_ChartData`,
        body
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const GetSchedult_II_III_V_ChartDetail = createAsyncThunk(
  "Reports/GetSchedult_II_III_V_ChartDetail",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL + REPORTAPI + `Dashboard/GetSchedule_II_III_V_ChartDetail`,
        body
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const GetDashboardKPI = createAsyncThunk(
  "Reports/GetDashboardKPI",
  async (body: any) => {
    const response = await axios.post(
      API_URL + REPORTAPI + `Dashboard/GetDashboardKPI`,
      body
    );
    return response.data;
  }
);

export const GetDashboardEnergyKPI = createAsyncThunk(
  "Dashboard/GetEnergyDashboardKPI",
  async (body: any) => {
    const response = await axios.post(
      API_URL + REPORTAPI + `Dashboard/GetEnergyDashboardKPI`,
      body
    );
    return response.data;
  }
);

export const GetExpectedReturnValueByCreditDisposition = createAsyncThunk(
  "Reports/GetExpectedReturnValueByCreditDisposition",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL +
        REPORTAPI +
        `Dashboard/GetExpectedReturnValueByCreditDisposition`,
        body
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const GetDashboardCreditPerServiceFeeDetail = createAsyncThunk(
  "Reports/GetDashboardCreditPerServiceFeeDetail",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL + REPORTAPI + `Dashboard/GetDashboardCreditPerServiceFeeDetail`,
        body
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const GetOrderAndUnitProcessed = createAsyncThunk(
  "Reports/GetOrderAndUnitProcessed",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Dashboard/GetOrderAndUnitProcessed`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const GetAccountHierarchy = createAsyncThunk(
  "Customers/GetServiceAccounts",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + INVENTORYAPI + `Customers/GetServiceAccounts`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const checkExpiry = createAsyncThunk(
  "User/VerifyUserInvite",
  async (body: any) => {
    let finalData;
    await axios
      .get(API_URL + USERMANAGEAPI + `User/VerifyUserInvite`, {
        params: body,
      })
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const checkExpiryChangepassword = createAsyncThunk(
  "User/VerifyChangePassword",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + USERMANAGEAPI + `User/VerifyChangePassword`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getProductDetailByNdc = createAsyncThunk(
  "api/ReturnOrder/GetProductDetailsByNdc",
  async (body: any) => {
    let finalData;
    await axios
      .get(API_URL + INVENTORYAPI + `ReturnOrder/GetProductDetailsByNdc`, {
        params: body,
      })
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const postReturnOrder = createAsyncThunk(
  "/api/ReturnOrder",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + INVENTORYAPI + `ReturnOrder`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getReturnOrder = createAsyncThunk(
  "/api/ReturnOrder/{id}",
  async (body: any) => {
    let finalData;
    await axios
      .get(API_URL + INVENTORYAPI + `ReturnOrder/` + body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const putReturnOrder = createAsyncThunk(
  "/api/ReturnOrder/{id}",
  async (body: any) => {
    let finalData;
    await axios
      .put(API_URL + INVENTORYAPI + `ReturnOrder/` + body.id, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getViewHistorySummary = createAsyncThunk(
  "ReturnOrder/GetReturnOrders",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + INVENTORYAPI + `ReturnOrder/GetReturnOrders`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const postNewNdc = createAsyncThunk(
  "ReturnOrder/RequestNewNdc",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + INVENTORYAPI + `ReturnOrder/RequestNewNdc`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const checkCustomerLicence = createAsyncThunk(
  "{id}/CheckCustomerLicense",
  async (body: any) => {
    let finalData;
    await axios
      .get(
        API_URL + `inventory/api/Customers/` + body + `/CheckCustomerLicense`
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const postImportInventory = createAsyncThunk(
  "ReturnOrder/{id}/ImportInventory",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL + INVENTORYAPI + `ReturnOrder/` + body.id + `/ImportInventory`,
        body
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const changePasswordRequest = createAsyncThunk(
  "User/ChangePassword",
  async () => {
    let finalData;
    await axios
      .post(API_URL + USERMANAGEAPI + `User/ChangePassword`, {})
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getOrderHistory = createAsyncThunk(
  "Reports/GetOrderHistory",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetOrderHistory`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getTransactionSummary = createAsyncThunk(
  "Reports/GetOrderSummary",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetOrderSummary`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const getDetailsOfSFG = createAsyncThunk(
  "Reports/GetSFGDetails",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetSFGDetails`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const get222FormDetails = createAsyncThunk(
  "Reports/Get222From",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/Get222From`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getMonthlyAccountStatement = createAsyncThunk(
  "Reports/GetReturnStatements",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetReturnStatements`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const getCreditStatement = createAsyncThunk(
  "Reports/GetStatementCredits",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/GetStatementCredits`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getDisposalReturns = createAsyncThunk(
  "Reports/DisposalReturns",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `Reports/DisposalReturns`, body)
      .then((response) => {
        finalData = response.data;
      });
    return finalData;
  }
);
export const getComapnyProfile = createAsyncThunk(
  "/Company/GetCompanyInfo",
  async () => {
    let finalData;
    await axios
      .get(API_URL + USERMANAGEAPI + `Company/GetCompanyInfo`)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const getAllNotification = createAsyncThunk(
  "/Notification/GetNotifications",
  async () => {
    let finalData;
    await axios
      .get(API_URL + NOTIFICATIONAPI + `Notification/GetNotifications`)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
export const makAsReadNotification = createAsyncThunk(
  "/Notification/ReadNotifications/{id}",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL +
        NOTIFICATIONAPI +
        `Notification/ReadNotifications?id=` +
        body.id +
        `&isReadAll=${body.isReadAll}`
      )
      .then((response) => {
        finalData = response.data;
      });
    return finalData;
  }
);
export const markAllAsReadNotification = createAsyncThunk(
  "/Notification/ReadNotifications",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL +
        NOTIFICATIONAPI +
        `Notification/ReadNotifications?isReadAll=${body.isReadAll}`
      )
      .then((response) => {
        finalData = response.data;
      });
    return finalData;
  }
);

export const postReportIssue = createAsyncThunk(
  "/usermanagement/api/Company/HelpReportIssue",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + USERMANAGEAPI + `Company/HelpReportIssue`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getUserRegistrationDetails = createAsyncThunk("User/GetUserRegistrationDetails", async (body: any, thunkAPI) => {
  const response = await axios.get(API_URL + USERMANAGEAPI + "User/GetUserRegistrationDetails", body);

  localStorage.setItem('registrationDetails', JSON.stringify(response?.data?.data));

  thunkAPI.dispatch(setRegistrationDetails(response?.data?.data));

  return response?.data;
});

export const validateUserRegistrationDetails = createAsyncThunk("/User/ValidateUserRegistrationDetails", async (body: any) => {
  let finalData;

  await axios.post(API_URL + USERMANAGEAPI + `User/ValidateUserRegistrationDetails`, body).then((response) => {
    finalData = response.data;
  });

  return finalData;
});

export const setUserPermissions = createAsyncThunk(
  "user/Permissions",
  async (body: any, thunkAPI) => {
    const response = await axios.post(
      API_URL + USERMANAGEAPI + `User/Permissions`,
      body
    );

    thunkAPI.dispatch(setPermissions(response?.data?.data));

    return response?.data?.data;
  }
);

export const getImpersonation = createAsyncThunk(
  "User/Impersonation",
  async (body: any) => {
    const response = await axios.get(
      API_URL +
      USERMANAGEAPI +
      `User/GetUserImpersonation?userEmail=${encodeURIComponent(body?.email)}`
    );
    return response?.data?.data;
  }
);

export const getImpersonatedPermissions = createAsyncThunk(
  "User/GetUserImpersonatedUserPermissions",
  async (body: any, thunkAPI) => {
    const response = await axios.get(
      API_URL + USERMANAGEAPI + "User/GetImpersonatedUserPermissions"
    );
    return response?.data;
  }
);
export const getBannerCMSList = createAsyncThunk(
  "DashboardMessage/getBannerCMSList",
  async (body: any, thunkAPI) => {
    const response = await axios.get(
      API_URL + REPORTAPI + "DashboardMessage/GetBannerCMSList"
    );
    return response?.data;
  }
);

export const customBannerSort = createAsyncThunk(
  "DashboardMessage/UpdateBannerMessageOrder",
  async (body: any, thunkAPI) => {
    const response = await axios.post(
      API_URL + REPORTAPI + `DashboardMessage/UpdateBannerMessageOrder`,
      body
    );
    return response?.data?.data;
  }
);

export const deleteItem = createAsyncThunk(
  "DashboardMessage/deleteItem",
  async (id: any) => {
    const response = await axios.delete(
      API_URL + REPORTAPI + `DashboardMessage/DeleteBannerMassage?Id=${id}`
    );
    return response?.data;
  }
);

export const addNewMessage = createAsyncThunk(
  "DashboardMessage/AddNewBannerMessage",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `DashboardMessage/AddNewBannerMessage`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const GetReports = createAsyncThunk(
  "ReportManagement/GetReports",
  async () => {
    let finalData;
    await axios
      .get(API_URL + REPORTAPI + `ReportManagement/GetReports`)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const postReports = createAsyncThunk(
  "ReportManagement/SubscribeReports",
  async (body: any) => {
    let finalData;
    await axios
      .post(API_URL + REPORTAPI + `ReportManagement/SubscribeReports`, body)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const checkUserLoggedInFirstTime = createAsyncThunk(
  "User/ProductTourStatus",
  async () => {
    let finalData;
    await axios
      .get(API_URL + USERMANAGEAPI + `User/ProductTourStatus`)
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);

export const getDrugLiquidationOrderHistoryReport = createAsyncThunk(
  "Reports/GetDrugLiquidationOrderHistory",
  async (body: any) => {
    let finalData;
    await axios
      .post(
        API_URL + REPORTAPI + `Reports/GetDrugLiquidationOrderHistory`,
        body
      )
      .then((response) => {
        finalData = response.data;
      });

    return finalData;
  }
);
const apiSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRegistrationDetails: (state: any, action) => {
      return {
        ...state,
        registrationDetails: { ...action.payload },
      };
    },
    setPermissions: (state: any, action) => {
      return {
        ...state,
        permissions: { ...action.payload },
      };
    },
    setUserSettings: (state: any, action) => {
      console.log(action.payload);
      return {
        ...state,
        userSettings: cloneDeep(action.payload),
      };
    },
    setSalesUser: (state: any, action) => {
      return {
        ...state,
        salesUserAccess: cloneDeep(action.payload),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.userInfo = action.payload;
      })
      .addCase(setUserPermissions.fulfilled, (state, action) => {
        state.status = "idle";
        state.permissions = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Get user profile data failed";
      });
  },
});

export const { setRegistrationDetails, setPermissions, setUserSettings, setSalesUser } = apiSlice.actions;
export default apiSlice.reducer;
